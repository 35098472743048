export default {
  technologies: [
    '.Net Core',
    'Asp .Net MVC5',
    'FluentValidation',
    'SignalR',
    'RabbitMQ',
    'AutoMapper',
    'AutoFac',
    'NUnit',
    'EntityFramework',
    'NLog',
    'ExpressJS',
    'Laravel',
    'Angular',
    'RxJS',
    'ReactJS',
    'Blazor',
    'JQuery',
    'KnockoutJS',
    'Angular Material ',
    'Material UI',
    'PrimeNG',
    'Bootstrap',
    'GIT',
    'AWS',
    'JIRA',
  ],
  certifications: [
    {
      title: 'Certified .Net Specialist',
      issuer: 'EVS Learning',
      issuerLink: 'https://www.evslearning.com/',
      issueDate: 'April, 2017',
      credentialTitle: 'See Credentials',
      credentialLink:
        '/downloads/Net_Specialist_Certificate.pdf',
    }
  ],
};
