export default [
  {
    degree: 'Masters of Science',
    subject: 'Web & Data Science',
    school: 'Universität Koblenz · Landau, Koblenz',
    // marks: {
    //   unit: 'CGPA',
    //   total: 4.0,
    //   obtained: 3.07,
    // },
    from: 'November 2020',
    to: 'Present',
  },
  {
    degree: 'Bachelor of Science',
    subject: 'Information Technology',
    school: 'University of the Punjab, Lahore',
    marks: {
      unit: 'CGPA',
      total: 4.0,
      obtained: 3.07,
    },
    from: 'November 2013',
    to: 'December 2017',
  }
];
